<template>
    <div id="welcome">
        <Header style="position: inherit;" :isAuthPage="true"/>
        <div class="welcome--cont">
            <div class="welcome--title--cont">
                <div class="title">
                    Welcome to HoraGuru!
                </div>
                <div class="description">
                    Please, set up key parameters required for proper calendar visualization!
                </div>    
            </div>
            <div class="welcome--field--cont">
                <b-field
                :type="form.shortNameError?
                    'is-danger' : ''"
                :message="form.shortNameError?
                    form.shortNameError : 
                    'Your short name being used in calendars'">
                    <b-input v-model="form.shortName" 
                    maxlength="30" 
                    placeholder="Short name"
                    @input="() => form.shortNameError = null">
                    </b-input>
                </b-field>

                <!-- <b-field                 
                :type="form.countryError?
                    'is-danger' : ''"
                :message="form.countryError?
                    form.countryError : ''">
                    <b-autocomplete
                        v-model="searchCountry"
                        :data="search(countries, searchCountry)"
                        field="name"
                        placeholder="Country"
                        clearable
                        @input="() => form.countryError = null"
                        @blur="() => {
                            if (searchCountry.length == 0)
                                updateCountryId(form.countryId)
                        }"
                        @select="option => {
                            if (option)
                                updateCountryId(option.id)
                        }">
                        <template #empty>No results found</template>
                    </b-autocomplete>
                </b-field> -->
                <b-field  
                :type="form.countryError?
                    'is-danger' : ''"
                :message="form.countryError?
                    form.countryError : ''">
                    <b-select placeholder="Country" expanded
                    @input="countryId => {
                        if (countryId)
                            updateCountryId(countryId)
                        form.countryError = null
                    }">
                        <option
                            v-for="option in countries"
                            :value="option.id"
                            :key="`country-${option.id}`">
                            {{ option.name }}
                        </option>
                    </b-select>
                </b-field>
                
                <b-field
                :type="form.timezoneError?
                    'is-danger' : ''"
                :message="form.timezoneError? 
                    form.timezoneError : ''">
                    <b-autocomplete
                        v-model="searchTimezone"
                        :data="search(timezones, searchTimezone)"
                        field="name"
                        placeholder="Timezone"
                        clearable
                        @input="() => form.timezoneError = null"
                        @blur="() => {
                            if (searchTimezone.length == 0)
                                updateTimezoneId(form.timezoneId)
                        }"
                        @select="option => {
                            if (option)
                                updateTimezoneId(option.id)
                        }">
                        <template #empty>No results found</template>
                    </b-autocomplete>
                </b-field>

                <!-- <b-input type="textarea" expanded readonly
                value="Terms and conditions of service using."
                custom-class="textarea has-fixed-size"></b-input>

                <b-field :type="form.isAcceptError?
                    'is-danger' : ''"
                :message="form.isAcceptError? 
                    form.isAcceptError : ''">
                    <b-checkbox v-model="form.isAccept">
                        I read and accept service usage rules
                    </b-checkbox>
                </b-field> -->

            </div>
        </div>
        <div class="welcome--submit">
            <b-button type="is-info" expanded @click="submit">
                Submit
            </b-button>
        </div>
    </div>
</template>

<script>
import CMS from '../service/cms/service.js'

import Field from '../components/inputs/Field.vue'
import Header from '../components/header/Header.vue'
import DropButton from '../components/inputs/DropButton.vue'
import router from '../router.js'

import WelcomeUserFormInstance from '../components/forms/templates/welcome-user.js'
import { formBus } from '../components/inputs/EventBus'

export default {
    name: "Welcome",
    components: {
        Field,
        Header,
        DropButton
    },
    computed: {
        timezones(){
            return this.$store.getters.timezones
        },
        countries(){
            return this.$store.getters.countries
        },
        // browserTimezone(){
        //     let browserTimezone = this.$store.getters.browserTimezone
        //     if (browserTimezone && browserTimezone.id && this.form.timezoneId == -1) {
        //         this.updateTimezoneId(browserTimezone.id)
        //     }
        //     return (browserTimezone)
        // }
    },
    data(){
        return{
            form: new WelcomeUserFormInstance(),
            searchTimezone: "",
            searchCountry: "",
        }
    },
    created(){
        // this.setupFieldSetters()
        this.setupShortName()
    },
    methods: {
        search(items, searchString){
            if (!searchString || searchString.length == 0)
                return items
            
            let search = searchString.toLowerCase()

            return items.filter(item => {
                if (item.title)
                    return item.title.toLowerCase().includes(search)
                else if (item.name)
                    return item.name.toLowerCase().includes(search)
                else if (item.Name)
                    return item.Name.toLowerCase().includes(search)
            })
        },
        updateCountryId(newCountryId){
            let oldCountryId = this.form.countryId
            this.form.countryId = newCountryId
            this.form.countryIndex = newCountryId - 1
            if (!newCountryId) 
                return 

            let currentCountry = this.countries.find((country) => {
                return country.id == newCountryId
            })

            // Check if timezone is already set by user
            if (oldCountryId > 0) 
            {
                let oldCountry = this.countries.find((country) => {
                    return country.id == oldCountryId
                })
                if (oldCountry.defaultTimezoneId != this.form.timezoneId)
                    return 
            }

            // Update timezone
            if (currentCountry) {
                this.searchCountry = currentCountry.name
                let timezoneId = currentCountry.defaultTimezoneId
                this.updateTimezoneId(timezoneId)
            }
        },
        updateTimezoneId(newTimezoneId) {
            this.form.timezoneId = newTimezoneId
            this.form.timezoneIndex = newTimezoneId - 1
            let currentTimezone = this.timezones.find((timezone) => {
                return timezone.id == newTimezoneId
            })
            if (currentTimezone)
                this.searchTimezone = currentTimezone.name
        },
        setupShortName(){
            let shortName = this.$store.state.user.firstName ||
                localStorage.getItem('hora-user-first-name')
            let lastName = this.$store.state.user.lastName ||
                localStorage.getItem('hora-user-last-name')
            if (shortName && lastName) {
                let firstSymbolOfName = lastName[0]
                shortName += " " + firstSymbolOfName + "."
            }
            
            this.form.shortName = shortName
        },

        submit(){
            
            if (!this.form.isValid())
                return 

            let submitData = {
                'firstName': this.$store.state.user.firstName,
                'lastName': this.$store.state.user.lastName,
                'avatarUrl': this.$store.state.user.imageUrl,
                'email': this.$store.state.user.email,
                shortName: this.form.shortName,
                countryId: this.form.countryId,
                timezoneId: this.form.timezoneId,
            }

            if (submitData['firstName'].length == 0)
                submitData['firstName'] = localStorage.getItem('hora-user-first-name')
            if (submitData['lastName'].length == 0)
                submitData['lastName'] = localStorage.getItem('hora-user-last-name')
            if (submitData['lastName'].length == 0)
                submitData['email'] = localStorage.getItem('hora-user-email')

            CMS.user.setInfo(submitData).then( userData => {
                if (userData.err){
                    throw userData.err
                }
                this.$store.dispatch('update-user-data')
                
                localStorage.removeItem('hora-user-first-name')
                localStorage.removeItem('hora-user-last-name')
                localStorage.removeItem('hora-user-email')
                // If calendar info is already exist
                let calendarInfoIsExists = Boolean(this.$store.getters.calendarUid)
                if (calendarInfoIsExists){
                    let lastCalendarVisibility = this.$store.getters.calendarVisibilityLevel
                    let isPublicForUser = lastCalendarVisibility == 2 || lastCalendarVisibility == 3
                    if (isPublicForUser){
                        let p = {calendarId: this.$store.getters.calendarUid}
                        let q = this.$store.getters.calendarProperties
                        router.replace({name:'calendar', params: p, query: q})
                        return
                    }
                }
                
                // If last calendar full path was saved
                let lastCalendarInLocalStorage = Boolean(localStorage.getItem("last-calendar"))
                if (lastCalendarInLocalStorage) {
                    router.replace(localStorage.getItem("last-calendar"))
                    localStorage.removeItem("last-calendar")
                    return
                }

                router.replace({name: 'calendar-list'}) // --> to calendar list
            })
        },
    },
}
</script>

<style scoped>

.field:not(:last-child){
    margin: 0;
}
.welcome--cont{
    padding: 24px;
    height: calc(100vh - 48px);
}
.welcome--title--cont{
    margin-bottom: 50px;
}

.welcome--title--cont .title{
    font-size: 24px;
    margin-bottom: 7px;
}
.welcome--title--cont .description{
    font-size: 14px;
}

.welcome--field--cont{
    display: flex;
    flex-direction: column;
    gap: 12px;
}



.welcome--rules textarea{
    width: calc(100% - 18px);
    font-family: 'Lato';
    padding: 7px;
    resize: none;
    overflow-y: auto;
}

.welcome--rules--accept{
    display: grid;
    grid-template-columns: 24px calc(100% - 24px - 10px);
    grid-column-gap: 10px;
}

.welcome--rules--accept > .label {
    font-size: 14px;
}   


input[type='checkbox']{
    height: 18px;
}

.welcome--submit{
    position: absolute;
    padding: 0px 24px;
    width: 100%;
    bottom: 30px;
}

.welcome--error{
    position: absolute;
    width: calc(100%);
    bottom: 100px;
    text-align: center;
    color: red;
    text-decoration: underline;
}

.terms {
    resize: none;
    height: 200px
}
</style>